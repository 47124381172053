<template>
    <div class="w-full text-center mt-5">
        <el-pagination
            background
            layout="prev, pager, next, sizes"
            :total="totalAmount"
            :current-page.sync="pageNumber"
            :page-size="pageSize"
            :page-sizes="[15, 100, 200, 500]"
            @size-change="handleSizeChange"
            @current-change="handleCurrentPageChange"
        />
    </div>
</template>
<script>
import queryString from "query-string";

export default {
    props: {
        totalAmount: {
            type: Number,
            default: 0,
        },
    },

    watch: {
        totalAmount() {
            this.updateUrl();
        },
    },

    data() {
        return {
            pageNumber: 1,
            pageSize: 15,
            timer: null,
        };
    },

    created() {
        this.assignInitValues();
    },

    methods: {
        assignInitValues() {
            const { pageNumber, pageSize } = queryString.parse(location.search);
            this.pageNumber = parseInt(pageNumber) || this.pageNumber;
            this.pageSize = parseInt(pageSize) || 15;
        },

        handleSizeChange(pageSize) {
            this.pageSize = pageSize;
            this.updateUrl();
            this.emitChange();
        },

        handleCurrentPageChange(pageNumber) {
            this.pageNumber = pageNumber;
            this.updateUrl();
            this.emitChange();
        },

        async updateUrl(pageNumber = null) {
            if (pageNumber) {
                this.pageNumber = pageNumber;
            }
            const query = queryString.parse(window.location.search, { sort: false });
            query.pageNumber = this.pageNumber;
            query.pageSize = this.pageSize;
            await this.$router.replace({ query }).catch(() => {});
        },

        emitChange() {
            clearInterval(this.timer);
            this.timer = setTimeout(() => {
                this.$emit("change");
            }, 400);
        },
    },
};
</script>
